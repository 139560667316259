<template>
    <div class="contaners_share">
        <img style="width:100%;height:100%" src="../../../assets/backgroundicon.png" />
        <div @click="mineMessage" class="messageTitle13">
            <img class="messageIcon" src="../../../assets/phone2.png" />
            <div>联系客服</div>
        </div>
        <div class="content_pattern">
            <div class="pattern_title">致用户:</div>
            <div class="pattern_text" style="color:#fff">您好，若您已经成为我平台会员，请按实际信息填写来激活身份。</div>
            <div class="pattern_text">会员身份则需要填写会员信息，股东用户需要填写合同中信息，请按实际填写，否则将激活失败。</div>
            <div class="select_box1">
                <van-radio-group v-model="checked" style="display:flex;color:#DEB77A;justify-content:space-between;">
                    <van-radio v-if="dataList.user.union_member==0" checked-color="#DEB77A" name="1" icon-size="15px" style="color:#fff"><span style="color:#DEB77A">我是工会会员</span></van-radio>
                    <van-radio v-if="dataList.user.union_shareholder==0" checked-color="#DEB77A" name="2" icon-size="15px"><span style="color:#DEB77A">我是股东会员</span></van-radio>
                </van-radio-group>
            </div>
            <div class="text1">注意:已经是股东的会员，只需要激活股东身份即可全部激活。</div>
            <div class="formSubmit1" v-if="checked==1">
                <div class="formList">
                    <div class="offine_title">姓名:</div>
                    <input v-model="dataForm1.username.value" />
                </div>
                <div>
                    <van-field style="width:230px" readonly clickable name="picker" :value="dataForm1.sex.value" :label="dataForm1.sex.title" :placeholder="dataForm1.sex.text" @click="showPicker = true" />
                    <van-popup v-model="showPicker" position="bottom">
                        <van-picker show-toolbar :columns="dataForm1.sex.list" @confirm="onConfirm1" @cancel="showPicker = false" />
                    </van-popup>
                </div>
                <div class="formList">
                    <div class="offine_title">联系方式:</div>
                    <input v-model="dataForm1.phone.value" />
                </div>
                <div class="formList">
                    <div class="offine_title">入会时间:</div>
                    <div @click="select" class="selectTimes">{{dataForm1.userTime.value}}</div>
                    <van-datetime-picker v-model="dataForm1.userTime.value" type="date" v-if="userShow" title="选择年月日" :min-date="minDate" :max-date="maxDate" @confirm="confirm" @cancel="cancel" />
                </div>
                <div class="formList">
                    <div class="offine_title">会员证编号:</div>
                    <input v-model="dataForm1.userCode.value" />
                </div>
                <div class="formList">
                    <div class="offine_title">证书上传:</div>

                    <uploadImg :isNumber="isNumber" :uploadData="uploadData" @uploadImg="uploadImg"></uploadImg>
                    <!-- <van-uploader v-on:oversize="oversize" class="imgFlexd" v-if="dataForm1.fileList.value.length==0" v-model="fileList" multiple :before-read="beforeRead1" :max-count="1" />
                     <div  v-if="dataForm1.fileList.value.length>0">
                        <div class="imgClass" v-for="(item,index) in dataForm1.fileList.value" :key="index" >
                        <img :src="item.url"/>
                        <div class="box_clone" @click="imgBtnClone(2,index)">
                            <img class="imgs" src="../../../assets/cloneIcon.png"/>
                        </div>
                    </div>
                    </div> -->
                </div>
            </div>

            <div class="formSubmit1" v-if="checked==2">
                <div class="formList">
                    <div class="offine_title" style="letter-spacing:20px">姓名</div>
                    <input v-model="dataForm.username.value" />
                </div>
                <div>
                    <van-field readonly clickable name="picker" :value="dataForm.sex.value" :label="dataForm.sex.title" :placeholder="dataForm.sex.text" @click="showPicker = true" />
                    <van-popup v-model="showPicker" position="bottom">
                        <van-picker show-toolbar :columns="dataForm.sex.list" @confirm="onConfirm1" @cancel="showPicker = false" />
                    </van-popup>
                </div>
                <div class="formList">
                    <div class="offine_title">联系方式</div>
                    <input v-model="dataForm.phone.value" />
                </div>
                <div class="formList">
                    <div class="offine_title">身份证号</div>
                    <input v-model="dataForm.idCard.value" />
                </div>
                <div class="item_list" style="margin-bottom:20px">
                    <van-radio-group v-model="checked1" class="selectBtn" style="display:flex;">
                        <van-radio name='1' shape="square">
                            <div class="selectText">协议类</div>
                        </van-radio>
                        <!--<van-radio style="margin-left:30px" name='2' shape="square"><div class="selectText">期权证</div></van-radio>-->
                    </van-radio-group>
                </div>

                <div class="formList" v-if="checked1==1">
                    <div class="offine_title">合同签订日期</div>
                    <div @click="select" class="selectTimes">{{dataForm.date.value}}</div>
                    <!-- <input @click="select" v-model="dataForm.date.value"/> -->
                    <van-datetime-picker v-model="dataForm.date.value" type="date" v-if="userShow" title="选择年月日" :min-date="minDate" :max-date="maxDate" @confirm="confirm" @cancel="cancel" />
                </div>
                <div class="formList" v-if="checked1==1">
                    <div class="offine_title">股东合同编号</div>
                    <input v-model="dataForm.code.value" />
                </div>

                <div class="text2" v-if="checked1==2">您需要根据已有证书填写对应得数据,如果为A类则填写A类,G类填写G类数据,如果同时拥有AG类,则需要填写与上传凭证。</div>
                <div v-if="checked1=='2'" style="display:flex" class="patterm">
                    <div style="display:flex">
                        A类<input v-model="dataForm.classA.value" />
                    </div>
                    <div style="display:flex">
                        G类<input v-model="dataForm.classB.value" />
                    </div>
                </div>
                <div class="formList" v-if="checked1==2">
                    <div class="offine_title">证书上传</div>
                    <van-uploader v-on:oversize="oversize" class="imgFlexd" v-if="fileList.length<3" multiple :before-read="beforeRead" :max-count="1" />

                    <div v-if="fileList.length>0" style="display:flex">
                        <div class="imgClass" v-for="(item,index) in fileList" :key="index">
                            <img :src="item.url" />
                            <div class="box_clone" @click="imgBtnClone(1,index)">
                                <img class="imgs" src="../../../assets/cloneIcon.png" />
                            </div>
                        </div>

                    </div>
                    <div style="color:#fff;font-size:14px;margin-top:10px;margin-left:10px">*最多三张</div>

                </div>
            </div>
            <!-- <div style="color:#fff" class="offine_text">*经系统检测,您的购享仅为:5000,可持有</div>
            <div style="color:#fff" class="offine_text">最大股份为:1000</div>
            <div class="address">收货详细地址:</div>
            <textarea class="shipping">

            </textarea> -->
            <div style="display:flex;">
                <div v-if="iconShow==false" @click="checkoutBtn(1)" class="select_box"></div>
                <div v-else class="select_box" @click="checkoutBtn(2)" style="background:#87CEFA"></div>
                <div class="ruleBtn" @click="ruleBtn">我已熟知与查看《工会股东协议》</div>
            </div>
            <!-- <div class="offine_text" style="color:#fff;">您已是会员用户,只能激活股东</div> -->
            <!-- <div class="offine_text"  style="color:#fff;">如不是请<span style="color:#DEB77A">申请股东></span></div> -->
            <div class="office_submit" @click="submit">
                <img src="../../../assets/submitBtn.png" />
            </div>

            <div class="ac_history" @click="historyBtn">查看历史激活></div>
        </div>

        <!-- 签字弹窗 -->
        <div class="signtureShow" v-if="signtureShow" @click="signtureShow">
            <div class="signtureShow_box">
                <catSignature :imgWidth="imgWidth" @signatureBtn="signatureBtn" :imgToken="imgToken" @cloneImg="cloneImg" @cloneBtn="cloneBtn" @imgUblod="imgUblod"></catSignature>
            </div>
        </div>

        <toast v-if="toastShow" :config="configData"></toast>
    </div>
</template>
<script>
// import wx from "weixin-js-sdk";
import * as apiCommon from "@/api/common";
import toast from "@/components/toast";
import Cookies from "js-cookie";
import catSignature from "../../../components/catSignature.vue";
import uploadImg from "../../../components/uploadImg";
export default {
  data() {
    return {
      date: "",
      show: false,
      // formatDate:"",
      fileList: [],
      dataForm: {
        username: {
          value: "",
          title: "姓名:",
          text: "请输入姓名",
          color: "#DEB77A"
        },
        sex: {
          value: "",
          title: "性别",
          text: "",
          color: "#DEB77A",
          list: ["女", "男"]
        },
        phone: {
          value: "",
          title: "联系方式",
          text: "请输入联系方式",
          color: "#DEB77A"
        },
        idCard: {
          value: "",
          title: "身份证号",
          text: "请输入身份证号",
          color: "#DEB77A"
        },
        date: {
          value: "",
          title: "协议签订日",
          text: "请输入协议签订日",
          color: "#DEB77A"
        },
        code: {
          value: "",
          title: "股东合同编号",
          text: "请输入股东合同编号",
          color: "#DEB77A"
        },
        nameImg: {
          value: "",
          title: "签名",
          text: "请输入签名",
          color: "#DEB77A"
        },
        classA: {
          value: "",
          title: "A类",
          text: "请输入A类",
          color: "#DEB77A"
        },
        classB: {
          value: "",
          title: "G类",
          text: "请输入G类",
          color: "#DEB77A"
        }
      },
      showPicker: false,
      dataForm1: {
        username: {
          value: "",
          title: "姓名",
          text: "请输入姓名",
          color: "#DEB77A"
        },
        sex: {
          value: "",
          title: "性别",
          text: "",
          color: "#DEB77A",
          list: ["女", "男"]
        },
        phone: {
          value: "",
          title: "联系方式",
          text: "请输入联系方式",
          color: "#DEB77A"
        },
        userTime: {
          value: "",
          title: "入会时间",
          text: "请输入入会时间",
          color: "#DEB77A"
        },
        userCode: {
          value: "",
          title: "会员证编号",
          text: "请输入会员证编号",
          color: "#DEB77A"
        },
        fileList: {
          value: [],
          title: "证书上传",
          text: "请输入证书上传",
          color: "#DEB77A"
        },
        nameImg: {
          value: "",
          title: "签名",
          text: "请输入签名",
          color: "#DEB77A"
        }
      },
      checked: "2",
      checked1: "1",
      imgWidth: 645,
      signtureShow: false,
      img: "",
      imgToken: "",
      imgConfig: "{ useCdnDomain: true }",
      dataList: {
        user: {}
      },
      iconShow: false,
      minDate: new Date(2020, 0, 1),
      maxDate: new Date(2025, 10, 1),
      userShow: false,
      configData: {
        title: ""
      },
      toastShow: false,
      submitShow: true,
      uploadData: {
        getToken: "",
        imgConfig: "{ useCdnDomain: true }",
        num: 10
      },
      isNumber:true
    };
  },
  components: {
    catSignature: catSignature,
    toast: toast,
    uploadImg:uploadImg
  },
  computed: {
    // formatDate(){
    //      return `${new Date().getMonth() + 1}/${new Date().getDate()}`
    //  }
  },
  created() {
    document.title = "身份激活";
    this.getToken();
    this.getList();
    if (this.checked == 1) {
      if (Cookies.get("dataForm1")) {
        this.dataForm1 = JSON.parse(Cookies.get("dataForm1"));
      }
      // Cookies.set("dataForm1",JSON.stringify(this.dataForm1));
    } else {
      // Cookies.set("dataForm1",JSON.stringify(this.dataForm));
      if (Cookies.get("dataForm")) {
        this.dataForm = JSON.parse(Cookies.get("dataForm"));
      }
    }
    console.log(this.dataForm, this.dataForm1);
  },
  methods: {
    cloneBtn() {
      this.signtureShow = false;
      this.iconShow = false;
    },
    ruleBtn() {
      if (this.checked == 1) {
        Cookies.set("dataForm1", JSON.stringify(this.dataForm1));
      } else {
        Cookies.set("dataForm", JSON.stringify(this.dataForm));
      }
      this.$router.push("../rule");
    },
    historyBtn() {
      if (this.checked == 1) {
        Cookies.set("dataForm1", JSON.stringify(this.dataForm1));
      } else {
        Cookies.set("dataForm", JSON.stringify(this.dataForm));
      }
      this.$router.push("../../audit/index");
    },
    onConfirm1(value) {
      this.dataForm1.sex.value = value;
      this.dataForm.sex.value = value;
      console.log(this.config, "config");
      this.showPicker = false;
    },
    select() {
      this.userShow = true;
    },
    confirm(value) {
      this.userShow = false;
      this.formatDate(value);
    },
    cancel() {
      this.userShow = false;
    },
    imgBtnClone(index, id) {
      if (index == 1) {
        this.fileList.splice(id, 1);
      } else {
        this.dataForm1.fileList.value.splice(id, 1);
      }
    },
    imgUblod(img) {
      console.log(img, "img");
      this.dataForm1.nameImg.value = img;
      this.dataForm.nameImg.value = img;
      this.popShow = true;
      this.signtureShow = false;
    },
    signatureBtn() {
      this.toastShow = true;
      this.configData.title = "签名不能为空";
      setTimeout(e => {
        console.log(e);
        this.toastShow = false;
      }, 1000);
    },
    cloneImg() {
      (this.dataForm.nameImg.value = ""), localStorage.removeItem("imgName");
    },
    submit() {
      console.log(1);
      let data;
      if (this.checked == 1) {
        data = {
          audit_type: this.checked,
          audit_info: {
            name: this.dataForm1.username.value,
            sex: this.dataForm1.sex.value,
            phone: this.dataForm1.phone.value,
            userTime: this.dataForm1.userTime.value,
            userCode: this.dataForm1.userCode.value,
            fileList: this.dataForm1.fileList.value,
            nameImg: this.dataForm1.nameImg.value
          }
        };
        for (var i in this.dataForm1) {
          if (!this.dataForm1[i].value) {
            this.toastShow = true;
            this.configData.title = this.dataForm1[i].text;
            setTimeout(() => {
              this.toastShow = false;
            }, 1000);
            return false;
          }
        }
      } else {
        // console.log(this.dataForm.date,"data")
        data = {
          audit_type: this.checked,
          audit_info: {
            username: this.dataForm.username.value,
            sex: this.dataForm.sex.value,
            phone: this.dataForm.phone.value,
            userTime: this.dataForm.date.value,
            idCard: this.dataForm.idCard.value,
            userCode: this.dataForm.code.value,
            fileList: this.fileList,
            nameImg: this.dataForm.nameImg.value,
            selectType: this.checked1
          }
        };
        if (this.checked1 == 2) {
          data.audit_info.classA = this.dataForm.classA.value;
          data.audit_info.classB = this.dataForm.classB.value;
        }
        //
        for (var j in this.dataForm) {
          if (this.checked1 == 2) {
            if (
              j == "date" ||
              (j == "classA" || j == "classB" || j == "code")
            ) {
              console.log(1);
            } else {
              if (!this.dataForm[j].value) {
                this.toastShow = true;
                this.configData.title = this.dataForm[j].text;
                setTimeout(() => {
                  this.toastShow = false;
                }, 1000);
                return false;
              }
            }
          } else {
            if (j == "classA" || j == "classB") {
              console.log(1);
            } else {
              if (!this.dataForm[j].value) {
                this.toastShow = true;
                this.configData.title = this.dataForm[j].text;
                setTimeout(() => {
                  this.toastShow = false;
                }, 1000);
                return false;
              }
            }
          }
        }
      }
      console.log(data, "data");
      // return false
      if (this.submitShow == false) {
        return false;
      }
      this.submitShow = false;
      apiCommon.putActivate_api(data).then(res => {
        this.submitShow = true;
        if (res.code == 0) {
          this.toastShow = true;
          this.configData.title = "提交成功";
          setTimeout(() => {
            this.toastShow = false;
            this.$router.push(`./details?id=${res.data.audit_id}`);
          }, 1000);
        } else {
          this.toastShow = true;
          this.configData.title = res.error;
          setTimeout(() => {
            this.toastShow = false;
          }, 1000);
        }
      });
    },
    onConfirm(value) {
      console.log(value, "value");
      this.show = false;
      this.formatDate(value);
      // console.log(this.dataForm1.userTime.value,"1111")
    },
    formatDate(data) {
      this.dataForm1.userTime.value = `${data.getFullYear()}-${data.getMonth() +
        1}-${data.getDate()}`;
      this.dataForm.date.value = `${data.getFullYear()}-${data.getMonth() +
        1}-${data.getDate()}`;
      console.log(this.dataForm1.userTime.value);
      this.$forceUpdate();
    },
    oversize() {},
    // 股东激活期权证书上传
    beforeRead(e) {
      console.log(e, "file");
      let img = e;
      this.upload(1, img);
    },
    // 工会激活期权证书上传
    beforeRead1(e) {
      console.log(e, "file");
      let img = e;
      this.upload(2, img);
    },
    async upload(index, img) {
      let fd = new FormData();
      fd.append("token", this.imgToken);
      fd.append("config", this.imgConfig);
      fd.append("file", img);
      console.log(fd, "fd");
      let imgRes = await apiCommon.uploadImg(fd);
      this.imgUrl = "https://cdn.health.healthplatform.xyz/" + imgRes.key;
      console.log(this.imgUrl, "1111");
      if (index == 1) {
        this.fileList.push({ url: this.imgUrl });
      } else if (index == 2) {
        this.dataForm1.fileList.value = [{ url: this.imgUrl }];
      } else {
        this.fileList2 = [{ url: this.imgUrl }];
      }
      console.log(
        this.dataForm1.fileList.value,
        "this.dataForm.fileList.value"
      );
      this.$forceUpdate();
    },
    async getToken() {
      let res = await apiCommon.getImgToken();
      if (!res || typeof res === "string" || res.error)
        return this.$toast.fail(res ? res.error || res : "获取七牛云信息失败!");
      this.imgToken = res.data;
      console.log(this.imgToken, "1223131");
    },
    mineMessage() {
      // wx.closeWindow();
      this.$router.push("../../lador/service");
    },
    checkoutBtn(index) {
      if (index == 1) {
        this.iconShow = true;
        this.signtureShow = true;
      } else {
        this.iconShow = false;
      }
      this.signtureShow = true;
      this.$forceUpdate();
    },
    async getList() {
      let res = await apiCommon.getUserInfo_api();
      if (res.code == 0) {
        console.log(1);
        this.dataList = res.data;
      }
    }
  }
};
</script>
<style type="text/css">
van-picker {
  width: 750px;
  height: 600px;
  position: fixed;
  bottom: 0;
  left: 0;
  font-size: 25px;
  z-index: 9999;
}
.van-datetime-picker {
  width: 750px;
  height: 600px;
  position: fixed;
  bottom: 0;
  left: 0;
  font-size: 25px;
  z-index: 9999;
}
.selectTimes {
  width: 348px;
  height: 54px;
  border: 2px solid #deb77a;
  opacity: 1;
  border-radius: 5px;
  padding-left: 20px;
  box-sizing: border-box;
}

.van-picker__cancel,
.van-picker__confirm {
  font-size: 30px !important;
}
.signtureShow {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
}
.signtureShow_box {
  width: 80%;
  background-color: #fff;
  margin: 150px auto 0;
  border-radius: 15px;
  padding-bottom: 20px;
}
.van-calendar__header-title {
  font-size: 25px;
}
.content_pattern .van-radio__label {
  color: #deb77a !important;
}
input {
  padding-left: 20px;
}
.contaners_share {
  width: 100%;
  height: 2000px;
  position: relative;
  font-size: 30px;
  color: #deb77a;
  padding-bottom: 40px;
}
.content_pattern {
  position: absolute;
  top: 0;
  left: 0;
  /* width:666px; */
  padding: 20px 45px;
}
.messageTitle13 .messageIcon {
  width: 36px;
  height: 36px;
  margin-right: 20px;
}
.messageTitle13 {
  text-align: right;
  margin-right: 20px;
  font-size: 20px;
  color: #fff;
  position: absolute;
  top: 20px;
  right: 10px;
}
.header_contract {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
.header_contract div {
  display: flex;
}
.header_contract img {
  width: 28px;
  height: 28px;
  margin-right: 10px;
}
.contranct_text1 {
  font-size: 30px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #ffffff;
  margin-top: 20px;
}
.contranct_text {
  font-size: 25px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #deb77a;
  opacity: 1;
  margin-top: 25px;
}
.contranct_box {
  width: 515px;
  height: 567px;
  margin: 291px auto 0;
  position: relative;
}
.contranct_box img {
  width: 100%;
  height: 100%;
}

.formSubmit1 {
  margin-top: 71px;
}
.formList input {
  background-color: transparent;
  border: 1px solid #deb77a;
  width: 348px;
  height: 54px;
  /* position: relative; */
}
.box_clone {
  width: 20px;
  /* height:20px; */
  border-radius: 20px;
  background-color: #fff;
  position: absolute;
  top: 0;
  right: 0;
}
.box_clone .imgs {
  width: 20px;
  height: 20px;
  border-radius: 20px;
}
.patterm input {
  border: none;
  width: 153px;
  border-bottom: 2px solid #fff;
  background-color: transparent;
  margin-right: 30px;
}
.patterm {
  font-size: 22px;
  color: #fff;
  margin-bottom: 30px;
}
.van-cell {
  font-size: 25px;
  width: 560px;
  height: 60px;
  background-color: transparent;
  margin-left: 30px;
  margin-bottom: 30px;
  padding: 0;
  /* border: 1px solid #DEB77A;
    width: 348px;
    height: 54px; */
}
.van-picker-column {
  font-size: 25px;
}
.ac_history {
  font-size: 25px;
  color: #deb77a;
  text-align: center;
}
.van-field__control {
  height: 60px;
}
.van-field__label {
  margin-left: 27px;
  font-size: 25px;
  letter-spacing: 40px;
  color: #deb77aff;
  width: 145px;
  line-height: 50px;
  margin-right: 10px;
}
.van-field__value {
  width: 349px;
  /* height: 45px; */
  /* border: 1px solid #DEB77A; */
  opacity: 1;
  border-radius: 0px;
}
.van-field__control {
  width: 370px;
  /* height: 45px; */
  border: 1px solid #deb77a;
  color: #deb77a;
  opacity: 1;
  border-radius: 0px;
  padding-left: 20px;
  box-sizing: border-box;
}
.formList {
  display: flex;
  line-height: 54px;
  /* margin-bottom: 40px; */
  margin: 0 60px 40px;
}
.offine_title {
  width: 150px;
  letter-spacing: 4px;
  font-size: 25px;
}
.offine_text {
  font-size: 22px;
  margin-bottom: 20px;
  text-align: center;
}
.shipping {
  width: 519px;
  height: 161px;
  border: 2px solid #deb77a;
  opacity: 1;
  border-radius: 5px;
  background-color: transparent;
  margin-bottom: 40px;
}
.address {
  margin: 48px auto 24px;
}

.ruleBtn {
  color: #deb77a !important;
  margin-left: 20px;
  font-size: 25px;
}
.select_box {
  width: 32px;
  height: 32px;
  border: 1px solid #deb77a;
  opacity: 1;
  border-radius: 0px;
  margin-left: 100px;
}
.office_submit {
  width: 206px;
  height: 68px;
  margin: 10px auto 0;
}
.office_submit img {
  width: 100%;
  height: 100%;
}
.pattern_title {
  font-size: 35px;
  margin-top: 30px;
}
.pattern_text {
  margin: 35px;
  font-size: 25px;
}
.select_box1 {
  margin: 0 auto;
  width: 500px;
  font-size: 25px;
}

.text1 {
  color: #fff;
  font-size: 22px;
  margin: 28px auto 0;
  text-align: center;
}

.text2 {
  color: #fff;
  font-size: 22px;
  margin: 28px auto 32px;
  /* text-align: ce */
}
.selectBtn {
  font-size: 22px;
  color: #deb77a;
}
.selectText {
  color: #deb77a;
}

.item_list {
  margin-left: 200px;
}
.imgClass {
  position: relative;
  display: flex;
}
.imgClass img {
  width: 80px;
  height: 80px;
}
</style>